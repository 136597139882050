/* eslint-disable no-prototype-builtins */
import { Injectable } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormMode, FormlyFieldCollection, IEditorValue } from '@rcg/core/models';
import { HTMLAttachmentResolverService } from '@rcg/core/services';
import { fieldObjectNestedFieldProperty, fieldObjectNestedFieldType } from '@rcg/core/utils/form-utils';
import { DateUtils } from '@rcg/standalone/utils/date-utils';
import { ChipsSettings } from '../fields/chips/chips.component';

@Injectable({
  providedIn: 'root',
})
export class FormValueConverterService {
  constructor(private htmlAttachmentResolver: HTMLAttachmentResolverService) {}

  getModelInitalValues(options: FormlyFormOptions, model: Record<string, unknown>, fields: FormlyFieldConfig[]) {
    model = this.mapObjectUndefinedValuesToNull(model);

    const jsonFields = this.getJsonDataFieldsNames(options);
    if (!this.hasJsonDataFields(jsonFields)) {
      return model;
    }

    // set json data fileds to model from hasura data field in form options settings
    const dataFieldName = this.getJsonDataFieldName(options);
    if (dataFieldName === null) {
      throw new Error('Data field name for json values in form options settings is null');
    }

    const dataFieldValues = (model[dataFieldName] ?? {}) as Record<string, unknown>;

    const jsonDataFields: Record<string, unknown> = {};

    // set json data inital value and spread model json data field properties to model level properties
    const fieldsObj = this.convertFieldsToObject(fields, 5);
    Object.keys(jsonFields).forEach((k) => {
      const value = dataFieldValues[k] ?? null;
      jsonDataFields[k] = value ?? this.getFieldDefaultValueForInsert(options, model, fieldsObj[k]);
    });

    const modelWithDataFields = { ...model, ...jsonDataFields };

    // delete json data field or will be duplicate data fields
    if (modelWithDataFields.hasOwnProperty(dataFieldName)) {
      delete modelWithDataFields[dataFieldName];
    }
    return modelWithDataFields;
  }

  private _mapSubmitModel(
    mode: FormMode,
    options: FormlyFormOptions,
    fields: FormlyFieldCollection,
    model: { [x: string]: unknown },
  ): { [x: string]: unknown } {
    const mapFn = options?.formState?.hooks?.mapSubmitModel;
    if (!mapFn || typeof mapFn !== 'function') return model;

    return mapFn({
      mode,
      options,
      fields,
      model,
    });
  }

  getFormValuesForInsert(
    options: FormlyFormOptions,
    formModel: { [x: string]: unknown },
    fields: FormlyFieldCollection,
    parentFormId: number | null | undefined,
  ): { [x: string]: unknown } {
    const jsonFieldNames = this.getJsonDataFieldsNames(options);
    const jsonDataFieldName = this.getJsonDataFieldName(options);
    const hasJsonFields = this.hasJsonDataFields(jsonFieldNames);

    if (hasJsonFields && !jsonDataFieldName) {
      throw new Error('Json field name settings not exists in hasura form settings');
    }

    const jsonFields: Record<string, unknown> = {};
    let variables: { [x: string]: unknown } = {};

    Object.entries(formModel).forEach(([key, value]) => {
      const field = fields[key];
      if (!field?.key) {
        // get only fields from model that are set in form definition
        return;
      }

      if (hasJsonFields && jsonFieldNames.hasOwnProperty(key)) {
        // model fields and json data fields
        jsonFields[key] = this.getFieldValueForInsert(field, value, true);
      } else {
        // model fields only
        if (field.type === 'attachment' && this.hasFormSharedAttachments(field)) {
          //  attachments with shared table on hasura
          const sharedAttachmentsName = field?.props?.['settings']?.sharedAttachmentsFieldName?.trim();

          if (variables[sharedAttachmentsName] === undefined) {
            variables[sharedAttachmentsName] = { data: [] };
          }
          const attachValues = this.getFieldValueForInsert(field, value, false) ?? [];
          (variables[sharedAttachmentsName] as { [x: string]: unknown })['data'] = [
            ...((variables[sharedAttachmentsName] as { data?: unknown[] })['data'] ?? []),
            ...attachValues,
          ];
        } else {
          // attachments with single table on hasura
          variables[key] = this.getFieldValueForInsert(field, value, false);
        }
      }
    });

    // set json data fields
    if (hasJsonFields) {
      variables[jsonDataFieldName!] = Object.keys(jsonFields).length > 0 ? jsonFields : {};
    }

    // set parent form id if exists
    const parentFormIdName = options.formState.parentFormIdName;
    if (parentFormIdName && parentFormId) {
      variables = { ...variables, [parentFormIdName]: parentFormId };
    }
    // additional form mode values
    const additionModelProperies = this.getAdditionalModelValues('insert', formModel, options, variables);

    if (additionModelProperies && Object.keys(additionModelProperies).length > 0) {
      variables = { ...variables, ...additionModelProperies };
    }

    variables = this._mapSubmitModel('insert', options, fields, variables);

    return options.formState?.noneGenericInsertInputDataVariables === true ? variables : { updateData: variables };
  }

  getFormValuesForUpdate(
    options: FormlyFormOptions,
    formModel: { [x: string]: unknown },
    fields: FormlyFieldCollection,
    formRecordId: number,
  ): {
    singleRelationValues: object;
    attachments: {
      field: FormlyFieldConfig;
      values: unknown;
    }[];
  } {
    const jsonFieldNames = this.getJsonDataFieldsNames(options);
    const hasJsonFields = this.hasJsonDataFields(jsonFieldNames);

    const jsonFields: Record<string, unknown> = {};
    let singleRelationVariables: { [x: string]: unknown } = {};

    const attachments: ReturnType<typeof this.getFormValuesForUpdate>['attachments'] = [];

    // get single relation values and json data fields
    Object.entries(formModel).forEach(([key, value]) => {
      const field = fields[key];
      if (!field?.key) return;

      if (field?.type === 'attachment') {
        // attachments process separatelly
        const attachValue = this.getFieldValueForUpdate(field, value, formRecordId, hasJsonFields && jsonFieldNames.hasOwnProperty(key));
        attachments.push({
          field,
          values: attachValue,
        });
        return;
      }

      if (hasJsonFields && jsonFieldNames.hasOwnProperty(key)) {
        // json data fields
        jsonFields[key] = this.getFieldValueForUpdate(field, value, formRecordId, true);
      } else {
        // single relation fields
        singleRelationVariables[key] = this.getFieldValueForUpdate(field, value, formRecordId, false);
      }
    });

    singleRelationVariables = this._mapSubmitModel('update', options, fields, singleRelationVariables);

    const singleRelationValues = hasJsonFields
      ? {
          id: formRecordId,
          updateData: singleRelationVariables,
          fields_data: jsonFields ?? {},
        }
      : {
          id: formRecordId,
          updateData: singleRelationVariables,
        };

    // many to many relations values
    return {
      singleRelationValues: singleRelationValues,
      attachments,
    };
  }

  private getNestedFieldValue<GetValueFn extends typeof this.getFieldValueForInsert | typeof this.getFieldValueForUpdate>(
    getValueFn: GetValueFn,
    getValueParams: Parameters<GetValueFn>,
  ) {
    const [field, value, ...restParams] = getValueParams;

    const newValue: { [x: string]: unknown } = {
      ...value,
    };

    const strKey = field.key!.toString();
    const nestedFields = (field as { [fieldObjectNestedFieldProperty]: FormlyFieldConfig[] })[fieldObjectNestedFieldProperty];

    for (const nestedField of nestedFields) {
      const subKey = nestedField.key!.toString().substring(strKey.length + 1);
      // eslint-disable-next-line @typescript-eslint/ban-types
      newValue[subKey] = (getValueFn as Function).apply(this, [nestedField, value[subKey], ...restParams]);
    }

    return newValue;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getFieldValueForInsert(field: FormlyFieldConfig, value: any, isJsonField: boolean): any {
    switch (field.type) {
      case fieldObjectNestedFieldType:
        return this.getNestedFieldValue(this.getFieldValueForInsert, [field, value, isJsonField]);
      case 'autocomplete':
        return isJsonField
          ? value && value?.value
            ? {
                label: value.label ?? 'Unknown',
                value: value.value,
                ...(value.data && field?.props?.settings?.saveAdditionalDataValue ? { data: value.data } : {}),
              }
            : null
          : value && value?.value
          ? value.value
          : null;
      case 'autocompleteTree':
        return value && value?.value ? value.value : null;
      case 'attachment': {
        if (!value || value?.length === 0) {
          return null;
        }
        const attachmentIdName = field!.props?.['settings']?.relations?.fkId;

        if (!attachmentIdName) {
          throw new Error('Form attachments nima nastavljen relations fkId v form settings props.settings.relations.fkId');
        }
        // shared multi field attachments hasura table
        if (this.hasFormSharedAttachments(field)) {
          const fieldKey = field?.props?.['settings']?.fieldKey?.trim();

          const attachValues =
            !value || value.length === 0
              ? []
              : (value as number[]).map((v) => ({
                  [attachmentIdName]: v,
                  [fieldKey]: field.key,
                }));
          return attachValues;
        }

        // single hasura tables attachments
        const insertAttachmentsData = (value as number[]).map((a) => {
          if (a < 0) {
            return {
              [attachmentIdName]: -a,
              inline: true,
            };
          } else {
            return {
              [attachmentIdName]: a,
            };
          }
        });
        return { data: insertAttachmentsData };
      }
      case 'time':
        return value && typeof value === 'number' ? value : 0;
      case 'phoneChips':
        if (field.props?.saveAsString) {
          if (!Array.isArray(value)) {
            // This should never happen because of PhoneChipsComponent.ensureArray
            // Added just to be safe.
            throw new Error('Invalid phoneChips value for submit (not an array)');
          }

          const len = value.length;
          if (len > 1 || field.props.maxNumbers !== 1) throw new Error('Cannot save multiple phoneChips numbers as string.');

          return value[0] ?? null;
        }

        return value;
      case 'chips': {
        if (!Array.isArray(value)) return value;

        const mapper = (field.props?.settings as ChipsSettings)?.chipValueMapper;
        if (!mapper || typeof mapper !== 'function') return value;
        return value.map((e) => mapper(e));
      }
      case 'date-picker': {
        const dt: Date = !value ? null : typeof value === 'string' ? new Date(value) : value;
        return DateUtils.formatDate(dt);
      }
      case 'htmleditor': {
        if (!value) {
          return null;
        }

        const editorValue = value as IEditorValue;
        if (editorValue.html) {
          return {
            ...editorValue,
            html: this.htmlAttachmentResolver.sealBlobs(editorValue.html),
          };
        }
        return editorValue;
      }
      default:
        return value ?? null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFieldValueForUpdate(field: FormlyFieldConfig, value: any, formRecordId: number, isJsonField: boolean): any {
    switch (field.type) {
      case fieldObjectNestedFieldType:
        return this.getNestedFieldValue(this.getFieldValueForUpdate, [field, value, formRecordId, isJsonField]);
      case 'attachment': {
        if (!value || value?.length === 0) {
          return [];
        }

        const settings = field?.props?.['settings']?.relations?.update;
        const attchmentRelationName = settings?.updateValueNames?.relationId;
        const attachmentFormName = settings?.updateValueNames?.formId;

        if (!(attchmentRelationName && attachmentFormName)) {
          throw new Error(
            `Napačni form settings za attachments: settings.relations.update.updateMutation or settings.relations.update.updateValueNames`,
          );
        }

        // shared multi field attachments hasura table
        if (this.hasFormSharedAttachments(field)) {
          return (
            (value as number[])?.map((a) => {
              return {
                [attchmentRelationName]: a,
                [attachmentFormName]: formRecordId,
                [field.props!.settings.fieldKey]: field.key,
              };
            }) ?? []
          );
        }
        // single hasura tables attachments
        return (
          (value as number[])?.map((a) => {
            return {
              [attchmentRelationName]: a < 0 ? -a : a,
              [attachmentFormName]: formRecordId,
              inline: a < 0 ? true : undefined,
            };
          }) ?? []
        );
      }
      case 'htmleditor': {
        if (!value) {
          return null;
        }

        const editorValue = value as IEditorValue;
        if (editorValue.html) {
          return {
            ...editorValue,
            html: this.htmlAttachmentResolver.sealBlobs(editorValue.html),
          };
        }
        return editorValue;
      }

      default:
        // get other values same as insert
        return this.getFieldValueForInsert(field, value, isJsonField);
    }
  }

  hasFormSharedAttachments(field: FormlyFieldConfig): boolean {
    return field?.props?.['settings']?.fieldKey?.trim() && field?.props?.['settings']?.sharedAttachmentsFieldName?.trim();
  }
  // get additional form values that are not displayed in GUI and form fields definition if set in formOptions state setttings
  private getAdditionalModelValues(
    formMode: FormMode,
    formModel: Record<string, unknown>,
    formOptions: FormlyFormOptions,
    formValues: Record<string, unknown>,
  ): object {
    const additionalModelFields =
      formMode == 'insert'
        ? formOptions.formState?.insertAdditionalModelFields ?? {}
        : formMode == 'update'
        ? formOptions.formState?.updateAdditionalModelFields ?? {}
        : {};

    const additionalValues: Record<string, unknown> = {};
    if (!additionalModelFields || Object.keys(additionalModelFields).length === 0) {
      return additionalValues;
    }
    for (const key of Object.keys(additionalModelFields)) {
      if (formModel.hasOwnProperty(key) && (!formValues.hasOwnProperty(key) || formValues[key] !== formModel[key])) {
        additionalValues[key] = formModel[key];
      }
    }
    return additionalValues;
  }

  private hasJsonDataFields(jsonFieldNames: Record<string, string> | undefined | null): boolean {
    if (jsonFieldNames && Object.keys(jsonFieldNames).length > 0) {
      return true;
    }
    return false;
  }

  private getJsonDataFieldsNames(options: FormlyFormOptions): Record<string, string> {
    const result: Record<string, string> = {};
    const jsonFields = options?.formState?.data_field?.fields;
    if (jsonFields === undefined || !Array.isArray(jsonFields) || jsonFields.length === 0) {
      return result;
    }
    jsonFields.forEach((e) => {
      result[e] = '';
    });
    return result;
  }

  private getJsonDataFieldName(options: FormlyFormOptions): string | null {
    return (options.formState.data_field?.name as string | null)?.trim() ?? null;
  }

  private mapObjectUndefinedValuesToNull(model: Record<string, unknown>) {
    if (model !== null && model !== undefined && typeof model === 'object' && Object.keys(model).length > 0) {
      // reset undefined to null
      Object.entries(model).forEach((entry) => {
        const [key] = entry;
        if (model[key] === undefined) {
          model[key] = null;
        }
      });
    }
    return model;
  }

  private convertFieldsToObject(fields: FormlyFieldConfig[], maxLevel: number): FormlyFieldCollection {
    let result: FormlyFieldCollection = {};

    if (!fields || fields.length === 0 || maxLevel <= 0) return result;

    for (const f of fields) {
      if (f.props && !!f.key) {
        result[f.key as string] = f;
      }

      if (f.fieldGroup && Array.isArray(f.fieldGroup) && f.fieldGroup.length > 0) {
        const fieldGroupResult = this.convertFieldsToObject(f.fieldGroup, maxLevel - 1);
        result = { ...result, ...fieldGroupResult }; // Merge the results
      }
    }
    return result;
  }

  private isInsertMode(options: FormlyFormOptions, model: Record<string, unknown>): boolean {
    const insert = !!options?.formState?.query_variable && !model?.[options.formState.query_variable];
    return insert;
  }

  private getFieldDefaultValueForInsert(options: FormlyFormOptions, model: Record<string, unknown>, field: FormlyFieldConfig | undefined) {
    if (!field) return null;
    return (
      (this.isInsertMode(options, model)
        ? typeof field.defaultValue === 'function'
          ? field.defaultValue()
          : field.defaultValue ?? null
        : null) ?? null
    );
  }
}
