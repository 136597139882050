import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as Get from '@npm-libs/ng-getx';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ActionsService } from '@rcg/actions/services/actions.service';
import { AuthService } from '@rcg/auth';
import { FormConfig, FormSubmitedPayload, IFormDialogConfig } from '@rcg/core/models';
import { fullscreenDialog } from '@rcg/standalone/utils/dialog-utils';
import { isNonNullable } from '@rcg/utils/type.utils';
import { filter, map, switchMap } from 'rxjs';

@Get.NgAutoDispose
@Component({
  selector: 'rcg-formly-form-dialog',
  templateUrl: './formly-form-dialog.component.html',
  styleUrls: ['./formly-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFormDialogComponent implements OnInit, AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogConfig: IFormDialogConfig,
    public dialogRef: MatDialogRef<FormlyFormDialogComponent>,
    private authService: AuthService,
    private actionsService: ActionsService,
  ) {}

  @ViewChild('dialog', { read: ElementRef }) dialog!: ElementRef<HTMLElement>;

  formConfig?: FormConfig;
  errorMessage = 'Napačne form nastavitve za dialog';

  private actionsR = new Get.Rx<
    {
      tooltip: string;
      icon: string;
      color?: string;
      exec: () => void;
    }[]
  >([]);

  public readonly actions$ = this.actionsR.value$;

  ngOnInit(): void {
    if (this.dialogConfig && Object.keys(this.dialogConfig).length > 0) {
      this.formConfig = {
        ...this.dialogConfig,
      };

      if (this.formConfig?.formId && this.formConfig.formrecordId && this.formConfig.formDialogActions !== false) {
        this.actionsR.subscribeTo(
          this.authService.tenant$.pipe(
            filter(isNonNullable),
            switchMap((tenant) =>
              this.actionsService
                .getFormActions({
                  tenantId: tenant.id,
                  organizationId: tenant.organization.id,
                  contextType: 'form',
                  formId: this.formConfig!.formId as number,
                  moduleId: 317,
                  recordId: this.formConfig!.formrecordId as number,
                })
                .pipe(
                  map((actions) =>
                    actions.map((action) => ({
                      tooltip: action.tooltip ?? 'Akcija',
                      icon: action.icon ?? 'build',
                      color: action.color,
                      exec: () => this.actionsService.executeAction(action),
                    })),
                  ),
                ),
            ),
          ),
        );
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.dialogConfig.openFullscreen) {
      fullscreenDialog(this.dialog.nativeElement);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmitSuccess(data: any) {
    if (this.dialogConfig?.onSubmitSuccessAction) {
      this.dialogConfig.onSubmitSuccessAction(data);
    }
    this.dialogRef.close();
  }

  onSubmitError(error: string) {
    if (this.dialogConfig?.onSubmitErrorAction) {
      this.dialogConfig.onSubmitErrorAction(error ?? 'Error');
    }
  }

  onFormSubmited(payload: FormSubmitedPayload) {
    if (this.dialogConfig?.onFormSubmited) {
      this.dialogConfig.onFormSubmited(payload, () => this.dialogRef.close());
    }
  }
}
